import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import ShowroomServicePage from "./pages/Showroom-service";
import OtherServicesPage from "./pages/Other-service";
import ContactUsPage from "./pages/Contact";
import OurTeamPage from "./pages/Our-team";
import OurCommitmentPage from "./pages/Our-commitment";
import MainLayout from "./layouts/MainLayout";
import ScrollToTop from "./hooks/scrollToTop";
import { Helmet } from "react-helmet";

import "./assets/App.css";


function App() {
  return (
    <Router>
      <MainLayout>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/our-commitment" element={<OurCommitmentPage />} />
          <Route path="/showroom-service" element={<ShowroomServicePage />} />
          <Route path="/other-services" element={<OtherServicesPage />} />
          <Route path="/our-team" element={<OurTeamPage />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default App;
