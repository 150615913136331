import MissionVision from "../assets/images/home/mission_vision.svg";
import ArrowInCircle from "../assets/images/icons/arrow_in_circle.svg";
import FocusEyeInCircle from "../assets/images/icons/focus_eye_in_circle.svg";
export default function ValuesSection() {
  return (
    <div className="w-full flex flex-col md:flex-row justify-center items-center gap-4 mb-24 py-10">
      {/* Left Column */}
      <div className="flex flex-col gap-4 items-center justify-center z-10 px-6 md:px-10 md:w-1/2">
        <img src={MissionVision} alt="Team Specs" className="" />
      </div>

      {/* Right Column */}
      <div className="flex flex-col gap-4 md:gap-6 items-left justify-center z-10 md:w-1/2 px-4 md:px-0">
        <h4 className="tracking-[0.2em] font-dmSans text-primaryOrange text-left text-md font-bold uppercase">
          leading business solution
        </h4>
        <p className="text-primaryRoute uppercase font-redHat font-extrabold text-left">
          <span className="block text-lg md:text-2xl lg:text-6xl md:mb-4 md:tracking-[0.1em]">
            We Help Our
          </span>
          <p className="block text-md md:text-xl lg:text-4xl md:tracking-[0.15em]">
            Clients<span className="text-primary-red pl-4">For Goal</span>
          </p>
        </p>
        <div className="text-balance">
          <p className="text-md font-normal font-dmSans">
            we are dedicated to driving the success of our clients. Our team's
            extensive
            <br /> industry experience and deep understanding of both the Qatari
            and Chinese
            <br />
            business landscapes enable us to provide unparalleled support and
            services.
          </p>
        </div>
        <div className="flex flex-col gap-4 mt-6 md:mt-0 px-4 md:px-4 md:pl-10">
          <div className="flex flex-row gap-2 bg-white md:border-l-8 md:border-primaryOrange">
            <div className="flex flex-col justify-center items-left gap-4 md:relative md:h-28">
              <img
                src={ArrowInCircle}
                alt="Arrow In Circle"
                className="md:absolute md:-left-12 h-16 w-16 md:h-auto md:w-auto"
              />
              <div className="pl-4 md:pl-12">
                <h2 className="uppercase font-bold font-dmSans text-primaryRoute text-lg">
                  mission
                </h2>
                <p className="text-grayFill font-normal font-dmSans text-md">
                  To provide unparalleled support and services, enabling
                  <br />
                  Sichuan-based and international companies to thrive in the GCC
                  market
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-2 bg-white md:border-l-8 md:border-primaryOrange">
            <div className="flex flex-col justify-center items-left gap-4 md:relative md:h-28">
              <img
                src={FocusEyeInCircle}
                alt="Focus Eye In Circle"
                className="md:absolute md:-left-12 h-16 w-16 md:h-auto md:w-auto"
              />
              <div className="pl-4 md:pl-12">
                <h2 className="uppercase font-bold font-dmSans text-primaryRoute text-lg">
                  vision
                </h2>
                <p className="text-grayFill font-normal font-dmSans text-md">
                  To provide unparalleled support and services, enabling
                  <br />
                  Sichuan-based and international companies to thrive in the
                  <br />
                  GCC market
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
