import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import PopoverMenu from "../components/forms/Popover";
import PagesFooter from "../components/PagesFooter";

interface MainLayoutProps {
  children: React.ReactNode;
}

const routes = [
  {
    id: 1,
    title: "Showroom",
    href: "/showroom-service"
  },
  {
    id: 2,
    title: "Other",
    href: "/other-services"
  }
];

export default function MainLayout({ children }: MainLayoutProps) {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className="">
      <Header
        headerType={isHomePage ? "Narrow" : "Full"}
        navigation={
          <>
            <nav className="flex flex-row flex-wrap font-redHat font-semibold justify-center items-center gap-4">
              <Link to="/" className="mr-4">
                Home
              </Link>
              <Link to="./About" className="text-nowrap mr-4">
                About Us
              </Link>
              <PopoverMenu
                title={"Services"}
                routes={routes}
              />
              <Link to="/our-commitment" className="text-nowrap mr-4">
                Our Commitment
              </Link>
              <Link to="/our-team" className="text-nowrap mr-4">
                Our Team
              </Link>
            </nav>
          </>
        }
      />
      <main>{children}</main>
      <div>{isHomePage ? <Footer /> : <PagesFooter />}</div>
    </div>
  );
}
