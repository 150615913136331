import tower from "../assets/images/tower.webp";
import desk from "../assets/images/desk.webp";
import handshake from "../assets/images/handshake.webp";
import NewsletterBackground from "../assets/images/Newsletter banner.png";
import FooterBubbles from "../assets/images/footer bubbles.png";
import GroupHands from "../assets/images/main/group-hands.png";
import MobileGroupHands from "../assets/images/main/group-hands-mobile.png";

export const pictures = {
  tower,
  desk,
  handshake,
  NewsletterBackground,
  FooterBubbles,
  GroupHands,
  MobileGroupHands
};