import TeamSpecsData from "../constants/static-data";

export default function TeamSpecs() {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-full md:w-9/12 flex flex-col gap-2 md:gap-6 lg:gap-12 justify-center items-center">
        <div className="w-full flex justify-start items-center">
          <h2 className="font-redHat px-4 text-2xl md:text-3xl lg:text-5xl font-extrabold text-black text-left">
            Our team includes specialists in:
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-4">
          {TeamSpecsData.map((item: SpecAttributes) => (
            <div
              key={item.id}
              className="bg-white p-6 flex flex-col justify-center items-center gap-4 shadow-md rounded-2xl"
            >
              <img
                src={item.image}
                alt={item.category}
                className="object-contain"
              />
              <div className="flex flex-col justify-center items-start">
                <h3 className="text-primaryRoute font-redHat text-xl md:text-2xl font-bold text-left">
                  {item.category}
                </h3>
                <span className="text-grayFill text-md md:text-lg font-normal font-dmSans text-left">
                  {item.description}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
