import WorkTogetherBg from "../assets/images/home/work_together.webp";
import { Button } from "../components/ui/button";

export default function WideWorkTogether() {
  return (
    <div className="relative flex flex-col justify-center items-center mb-14">
      <img
        src={WorkTogetherBg}
        alt="Work Together Background"
        className="object-cover min-h-[20rem] md:min-h-[15rem] lg:min-h-[10rem] w-full"
      />
      <div className="absolute w-full px-10 flex flex-row flex-wrap justify-around items-center gap-4 z-10 top-1/2 transform -translate-y-1/2">
        <h3 className="text-xl md:text-3xl font-redHat font-bold text-white uppercase tracking-normal text-center px-4">
          Need Any Business Consultancy?{" "}
          <span className="text-primaryOrange">Contact With Us</span>
        </h3>
        <Button size="lg" variant={"secondary"}>
          let's work together
        </Button>
      </div>
    </div>
  );
}
