import FullWidthBanner from "../components/Full-width-page-banner";
import OurCommitmentBannerImage from "../assets/images/main/our-commitment-banner.png";
import OurCommitment from "../components/Our-commitment";

export default function OurCommitmentPage() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 bg-inputFill">
      <FullWidthBanner
        image={OurCommitmentBannerImage}
        routing={"Our Commitment"}
        LinkRoute={
          <>
            <p className="flex gap-2 font-dmSans font-normal text-md text-grayFill">
              Home
              <span className="text-primary-red">/</span>
              <span className="text-primaryRoute font-medium">
                Our Commitment{" "}
              </span>
            </p>
          </>
        }
      />
      <div className="py-4 md:py-10">
        <OurCommitment />
      </div>
    </div>
  );
}
