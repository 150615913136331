import React from "react";
import { Link } from "react-router-dom";

interface LinkProps {
  to: string;
  text: string;
  variant?: "primary" | "outline";
  className?: string;
  icon?: React.ReactNode;
  size?: "md"; // Define size options
}

export default function LinkAsButton({
  to,
  text,
  variant = "primary",
  className = "",
  icon,
  size = "md",
}: LinkProps) {
  const sizeClasses = {
    md: " py-2 px-4 text-sm  md:py-2 md:px-4  md:text-lg",
  };

  return (
    <Link
      to={to}
      className={`flex w-fit justify-center items-center rounded-xl font-montserrat text-nowrap font-medium cursor-pointer transition-all duration-300 ${variant === "primary"
        ? "bg-primaryOrange border-2 border-transparent text-white hover:bg-transparent hover:border-primaryOrange"
        : "bg-transparent border-2 border-primary-red text-white hover:bg-primary-red"
        } ${sizeClasses[size]} ${className}`}
    >
      <span className="flex items-center gap-2">
        {text} {icon}
      </span>
    </Link>
  );
}
