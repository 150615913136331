import { Link } from "react-router-dom";
import {
  MailIcon,
  ClockIcon,
} from "../components/icons/Social-media";
import { useAboutData, useSocialMediaData } from '../context/companyDataContext';
import { useEffect } from "react";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function NarrowHeader() {
  const aboutData = useAboutData();
  const socialMediaData = useSocialMediaData();
  const email = aboutData?.emails.primary;
  const socialLinks = socialMediaData;
  useEffect(() => {
    console.log(socialLinks)
  }, [])
  return (
    <div className="hidden py-2 md:py-0 bg-primary-red h-auto md:h-10 w-full lg:flex flex-col md:flex-row justify-center md:justify-around items-center">
      <div className="flex flex-col md:flex-row justify-center items-center gap-2 md:gap-4">
        <p className="flex flex-row justify-center items-center gap-2">
          <span>
            <MailIcon FillColor={"#FFFFFF"} />
          </span>
          <span className="font-dmSans font-medium text-white text-md">
            {email}
          </span>
        </p>
        <div className="hidden md:block  h-6 bg-divider w-[2px]" />
        <p className="flex flex-row justify-center items-center gap-2">
          <span>
            <ClockIcon FillColor={"#FFFFFF"} />
          </span>
          <span className="font-dmSans font-medium text-white text-md">
            Working: 9.00am - 5.00pm
          </span>
        </p>
      </div>
      <div className="flex flex-row justify-center items-center gap-4 mt-2 md:mt-0">
        <div className="hidden md:block h-6 bg-divider w-[2px]" />
        {socialLinks?.map((item: any) => (
          item.status ? (
            <Link key={item.id} to={item.link}>
              <img src={`${baseURL}/${item.icon}`} alt={item.name} />
            </Link>
          ) : (
            <Link key={item.id} to={item.link}>
              <img src={`${item.icon}`} alt={item.name} />
            </Link>
          )
        ))}
      </div>
    </div>
  );
}
