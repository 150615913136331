import GroupDisk from "../assets/images/contact-us/group-disk.png";
import TwoPersons from "../assets/images/contact-us/two-person-discussion.png";
import TeamColab from "../assets/images/contact-us/team-collab.png";
import {
  CallCenterIcon,
  MapWithMarker,
  EnvelopeIcon,
} from "../components/icons/Social-media";
import { useAboutData } from '../context/companyDataContext';


export default function ContactOptions() {
  const aboutData = useAboutData();
  const data: ContactOptionsProps[] = [
    {
      id: 1,
      title: "call us 24/7",
      icon: <CallCenterIcon FillColor={"#DF0A0A"} />,
      image: GroupDisk,
      text: aboutData?.phone_numbers.numbers,
    },
    {
      id: 2,
      title: "make a quote",
      icon: <EnvelopeIcon FillColor={"#DF0A0A"} />,
      image: TwoPersons,
      text: aboutData?.emails.emails,
    },
    {
      id: 3,
      title: "service station",
      icon: <MapWithMarker FillColor={"#DF0A0A"} />,
      image: TeamColab,
      text: aboutData?.locations.locations,
    },
  ];
  return (
    <div className="w-full flex flex-col justify-center items-center gap-4 py-10">
      <div className="w-full md:w-11/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8 px-4">
        {data.map((item) => (
          <div
            key={item.id}
            className=" w-fit border-2 border-[#F0F0F0] rounded-xl"
          >
            <img
              className="rounded-tr-xl rounded-tl-xl"
              src={item.image}
              alt={`${item.title} icon`}
            />
            <div className="w-full flex justify-center items-center py-2">
              <h3 className="text-primary-red font-dmSans font-medium text-lg uppercase">
                {item.title}
              </h3>
            </div>
            <div className="w-full h-fit min-h-[6rem] flex flex-row justify-center items-center py-2 gap-6">
              <span>{item.icon}</span>
              <div className="font-redHat font-normal text-md text-primaryRoute">
                {item.text?.map((line: any, index: number) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
