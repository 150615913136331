import FullWidthBanner from "../components/Full-width-page-banner";
import OurTeamBannerImage from "../assets/images/main/our-team-banner.png";
import ContactOptions from "../components/Contact-options";
import Map from "../components/Map";
import ContactForm from "../components/forms/Contact-form";

export default function ContactUsPage() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 bg-white">
      <FullWidthBanner
        image={OurTeamBannerImage}
        routing={"Our Team"}
        LinkRoute={
          <>
            <p className="flex gap-2 font-dmSans font-normal text-md text-grayFill">
              Home
              <span className="text-primary-red">/</span>
              <span className="text-primaryRoute font-medium">
                Contact Us{" "}
              </span>
            </p>
          </>
        }
      />
      <div className="py-4 md:py-10">
        <ContactOptions />
      </div>
      <div className="py-4 md:py-10">
        <Map />
      </div>
      <div className="py-4 md:py-10">
        <ContactForm />
      </div>
    </div>
  );
}
