import map from "../assets/images/map/map.webp";
import mapLogo from "../assets/images/map/mapLogo.webp";
import { Link } from "react-router-dom";
const Map = () => {
  return (
    <Link to={"https://maps.app.goo.gl/SaB8qxxHTp3SMYDo7"} className="relative">
      <img src={map} className="w-full h-auto" />
      <img
        src={mapLogo}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-10 w-10 sm:w-auto sm:h-auto"
      />
    </Link>
  );
};

export default Map;
