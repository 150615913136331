import FirstTower from "../assets/images/about-us/tower-1.webp";
import SecondTower from "../assets/images/about-us/tower-2.webp";

export default function AboutStats() {
  return (
    <div className="w-full flex justify-center items-center py-10">
      <div className="h-full flex flex-col md:flex-row justify-center md:gap-8 items-center w-full md:w-10/12">
        <div className="flex flex-col lg:flex-row gap-4 md:gap-8 justify-center items-center px-8 md:px-0 md:w-1/2 h-full">
          <div className="flex flex-col justify-end items-center gap-2 lg:mt-32">
            <div className="hidden lg:block border-b-8 border-primary-red w-full" />
            <img className="h-auto" alt="Culture Image" src={FirstTower} />
          </div>
          <div className="h-full flex flex-col justify-center items-center lg:mb-16">
            <img className="h-auto" alt="Culture Image" src={SecondTower} />
          </div>
        </div>

        <div className="w-full md:w-1/2 px-8 md:px-0 flex flex-col justify-center items-start gap-6">
          <h2 className="uppercase font-redHat font-semibold text-md tracking-widest text-primary-red py-2">
            just a consultancy
          </h2>
          <h1 className="capitalize font-redHat font-bold text-5xl text-primaryRoute">
            at xinhua age trading
          </h1>
          <div className="w-full">
            <p className="text-lg font-normal font-dmSans text-grayFill">
              was founded through the collaborative efforts of a prominent
              Qatari group and a diverse conglomerate of Sichuan-based
              companies. Our mission is to serve as a vital bridge, facilitating
              business and cultural exchange between Qatar and China. With our
              headquarters in Doha, we are strategically positioned to support
              companies in their pursuit of new market opportunities across the
              GCC (Gulf Cooperation Council).
            </p>
          </div>
          <div className="p-5 w-full flex flex-col gap-4 justify-center items-center bg-inputFill">
            <div className="flex flex-col gap-2 justify-center items-center w-full">
              <div className="flex flex-row justify-between items-center w-full">
                <span className="capitalize text-lg font-dmSans font-normal text-primaryRoute">
                  business strategy
                </span>
                <span className="capitalize text-lg font-dmSans font-normal text-primary-red">
                  73%
                </span>
              </div>
              <div className="w-full pl-2 bg-white h-4 rounded-md flex justify-start items-center">
                <div className="bg-primary-red w-9/12 h-2 rounded-md" />
              </div>
              <div className="w-full flex flex-row justify-between items-center">
                <span className="capitalize text-lg font-dmSans font-normal text-primaryRoute">
                  company strength
                </span>
                <span className="capitalize text-lg font-dmSans font-normal text-primary-red">
                  62%
                </span>
              </div>
              <div className="w-full bg-white pl-2 h-4 rounded-md flex justify-start items-center">
                <div className="bg-primary-red w-8/12 h-2 rounded-md" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
