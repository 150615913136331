"use client";

import { useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Send } from "lucide-react";

export default function InputwithButton() {
  const [email, setEmail] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle email submission here
    // console.log("Email submitted:", email);
    setEmail("");
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full flex"
    >
      <Input
        type="email"
        placeholder="Enter Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="rounded-md bg-white shadow-sm border-0 py-6 w-full placeholder:text-grayFill placeholder:font-dmSans placeholder:text-md"
        required
      />
      <Button
        type="submit"
        size="icon"
        className="rounded-md bg-[#FF4500] p-6 hover:bg-[#FF4500]/90 -ml-4"
      >
        <Send className="h-4 w-4" />
        <span className="sr-only">
          Submit
        </span>
      </Button>
    </form>
  );
}
