import redWideBg from "../assets/images/about-us/red-wide-narrow-banner.webp";
import { Button } from "../components/ui/button";

export default function NarrowBannerSection() {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="relative flex flex-col justify-center items-center w-full md:w-10/12">
        <img
          src={redWideBg}
          alt="Work Together Background"
          className="object-cover min-h-[20rem] md:min-h-[15rem] lg:min-h-[10rem] w-full"
        />
        <div className="absolute w-full flex flex-row flex-wrap justify-around items-center px-4 md:px-4 lg:px-0 gap-4 z-10 top-1/2 transform -translate-y-1/2">
          <h3 className="text-lg md:text-2xl lg:text-3xl font-redHat font-bold text-white uppercase tracking-normal text-center md:text-left">
            Let’s discuss about how we can help make
            <br />
            your business better
          </h3>
          <Button size="lg" variant={"secondary"}>
            let's work together
          </Button>
        </div>
      </div>
    </div>
  );
}
