export default function BridgingSection() {
  return (
    <div className="flex flex-col justify-center items-center gap-10">
      <div className="relative flex justify-center items-center">
        <h2 className="text-black font-redHat font-bold text-5xl text-center z-2 pt-5">
          Bridging China, Qatar, the GCC,
          <br />
          and the Middle East
        </h2>
        {/* <div
          className="absolute top-0 font-redHat text-9xl font-extrabold text-white -z-10"
          style={{ WebkitTextStroke: "1px #F0F0F0" }}
        >
          Services
        </div> */}
      </div>
      <div className="flex flex-col lg:flex-row justify-center items-center gap-4 flex-wrap  lg:items-stretch">
        <div className="w-10/12 lg:w-[45%] px-10 py-2 md:py-6 lg:px-10 lg:py-16 flex justify-center items-center text-xl md:text-2xl bg-white text-grayFill rounded-xl text-balance lg:min-h-full ">
          Our mission goes beyond business transactions; we aim to build a
          robust bridge between China, Qatar, the GCC, and the broader Middle
          East. We are committed to breaking down the challenges that businesses
          often face in these regions, including:
        </div>
        <div className="w-10/12 lg:w-[45%] px-10 py-2 md:py-6 lg:px-10 lg:py-16 flex justify-center items-center bg-white rounded-xl min-h-auto lg:min-h-[30rem] ">
          <ul className="list-disc text-grayFill">
            <li className="text-md md:text-lg">
              Language Barriers: Our multilingual team ensures clear and
              effective communication across different languages and cultures.
            </li>
            <li className="text-md md:text-lg">
              Cultural Differences: We understand the nuances of cross-cultural
              interactions and are skilled in facilitating smooth business
              operations.
            </li>
            <li className="text-md md:text-lg">
              Legal and Market Compliance: Our legal experts help navigate the
              complex regulatory environments, ensuring that all business
              activities comply with local laws and standards.
            </li>
            <li className="text-md md:text-lg">
              Market Entry and Expansion: We provide comprehensive support for
              market entry strategies, helping businesses establish and grow
              their presence in new markets.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
