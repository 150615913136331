import CultureImage from "../assets/images/home/culture.svg";
import BackgroundCultureImage from "../assets/images/home/culture_background.png";
import CheckmarkIcon from "../assets/images/icons/checkmark-with-circle.svg";

const stats = [
  { label: "Founded", value: "2021" },
  { label: "Employees", value: "37" },
  { label: "Countries", value: "12" },
  { label: "Raised", value: "$25M" },
];

export default function Culture() {
  return (
    <div className="relative py-24 sm:py-32">
      <img
        src={BackgroundCultureImage}
        alt="Background"
        className="absolute inset-0 h-full w-full object-cover brightness-100 -z-10"
      />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 z-10">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-16 gap-y-12 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-4">
            <img alt="Culture Image" src={CultureImage} />
          </div>
          <div>
            <div className="text-base/7 text-gray-700 lg:max-w-lg">
              <h1 className="mt-2 text-pretty text-2xl uppercase font-semibold tracking-tight text-gray-900">
                Bridging business and culture
                <br />
                <span className="text-red-500 uppercase">
                  Between qatar and china.
                </span>
              </h1>
              <div className="max-w-xl py-8">
                <p className="relative pl-6">
                  <span className="absolute left-0 top-1/2 transform -translate-y-1/2 h-28 border-l-4 border-red-600"></span>
                  Facilitating trade and cultural exchange between Qatar and
                  China, our headquarters in
                  <br />
                  Doha puts us in a strategic position to support companies in
                  exploring market
                  <br />
                  opportunities across the GCC.
                </p>
              </div>
            </div>
            <div className="flex flex-col text-base/7 text-black lg:max-w-lg">
              <ul>
                <li className="flex gap-2">
                  <img src={CheckmarkIcon} alt="Checkmark Icon" /> Showroom
                  Services
                </li>
                <li className="flex gap-2">
                  <img src={CheckmarkIcon} alt="Checkmark Icon" /> Consultancy &
                  Management Services
                </li>
                <li className="flex gap-2">
                  <img src={CheckmarkIcon} alt="Checkmark Icon" /> Trade Desk
                </li>
                <li className="flex gap-2">
                  <img src={CheckmarkIcon} alt="Checkmark Icon" /> Strategic
                  partnerships and market entry
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
