import { pictures } from "../constants/pictures";
import FeaturesCard from "./FeaturesCard";

const data: FeatureProps[] = [
  {
    id: 1,
    image: pictures.tower,
    title: "showroom and sales services",
    subTitle:
      "Our 1,200 square meter showroom is located on the 4th floor of the prestigious UDC Tower in the Pearl, Doha.",
    link: "/",
  },
  {
    id: 2,
    image: pictures.handshake,
    title: "financial and managerial consultancy",
    subTitle:
      "Xinhua Age Trading brings together a team of seasoned business professionals with extensive experience in both the Qatari and Chinese markets.",
    link: "/",
  },
  {
    id: 3,
    image: pictures.desk,
    title: "international trading",
    subTitle:
      "Our trade desk in Doha is equipped to handle a wide range of commodities, including",
    link: "/",
  },
];

const OurFeatures = () => {
  return (
    <div className="container mx-auto px-4 py-10 md:py-20 mb-16">
      <div className="flex flex-col items-center mb-10 md:mb-20">
        <p className="text-[#F64A00] text-lg md:text-xl tracking-[0.4rem] font-dmSans font-bold uppercase text-center">
          Our Features
        </p>
        <h1 className="font-bold text-3xl md:text-5xl uppercase text-center mt-2 font-redHat text-primaryRoute">
          what we do
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10">
        {data.map((item: FeatureProps) => (
          <FeaturesCard
            key={item.id}
            image={item.image}
            mainTitle={item.title}
            subTitle={item.subTitle}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

export default OurFeatures;
