import React from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from "react-router-dom";


const PopoverMenu: React.FC<PopoverMenuProps> = ({ title, routes }) => {
    return (
        <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-lg font-medium font-redHat text-black ">
                {title}
                <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-black" />
            </PopoverButton>

            <PopoverPanel
                transition
                className="absolute -left-8 top-full z-10 mt-3 w-fit max-w-md overflow-hidden rounded-3xl bg-white shadow-lg  transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="p-4">
                    {routes.map((item) => (
                        <div
                            key={item.id}
                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-50"
                        >
                            <div className="flex-auto">
                                <Link to={item.href} className="block font-semibold text-gray-900">
                                    {item.title}
                                    <span className="absolute inset-0" />
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </PopoverPanel>
        </Popover>
    );
};

export default PopoverMenu;
