"use client";

import LinkAsButton from "./ui/linkAsButton";
import StrokeText from "./ui/stroke-text/stroke-text";

export default function Hero() {
  return (
    <div className="relative bg-hero bg-cover isolate overflow-hidden pt-14 w-full">
      {/* <div
        className="absolute inset-0 translate-x-1/3 translate-y-1/3 font-redHat text-9xl text-transparent font-extrabold -z-10"
        style={{ WebkitTextStroke: "1px #F0F0F0" }}
      >
        Services
      </div> */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 md:py-48 lg:py-56">
          <div className="flex justify-start">
            <p className="font-semibold font-dmSans text-white uppercase tracking-[0.37rem] text-left py-4">
              International Trade and Development
            </p>
          </div>
          <div className="text-left">
            <h1 className="uppercase md:text-nowrap  font-redHat text-5xl font-bold tracking-tight text-white md:text-6xl lg:text-7xl">
              XINHUA Age Trading
            </h1>
            <p className="mt-8 text-left text-md font-medium text-gray-400 font-dmSans">
              Transcending business transactions to build a robust bridge
              between
              <br />
              China, Qatar, the GCC, and the broader Middle East.
            </p>
            <div className="mt-10 flex items-center justify-start gap-x-6">
              <LinkAsButton to="#" text="Contact Us" variant="primary" />
              <LinkAsButton to="#" text="About Us" variant="outline" />
            </div>
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
}
