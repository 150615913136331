import WhyUsBg from "../assets/images/home/why_choose_us.webp";
import TeamSpecsBg from "../assets/images/home/team_specs.webp";

export default function WhyChooseUs() {
  return (
    <div className="relative w-full flex flex-col md:flex-row justify-center items-center gap-4 min-h-[500px]  py-10">
      {/* Background Image */}
      <img
        src={WhyUsBg}
        alt="Why Choose Us Background"
        className="absolute top-0 left-0 w-full h-full object-cover -z-10"
      />

      {/* Left Column */}
      <div className="flex flex-col gap-4 items-left justify-center z-10 md:px-8">
        <h4 className="text-primaryOrange text-left text-lg font-bold uppercase px-4">
          why choose us
        </h4>
        <p className="text-white text-lg md:text-2xl lg:text-6xl uppercase font-redHat font-extrabold text-left px-4">
          a diverse and
          <br />
          <span className="text-primary-red">experienced team</span>
        </p>
        <div className="bg-white rounded-lg py-4 px-8 mt-2 md:mt-8">
          <h2 className="text-primaryOrange text-lg">
            Our team includes specialists in:
          </h2>
          <p className="text-grayFill text-md text-nowrap">
            Financial and Managerial Experience
            <br />
            Trade and Commerce
            <br />
            Industrial Engineering
            <br />
            Technology and AI
            <br />
            Furniture & Design
            <br />
            Legal and Compliance
            <br />
          </p>
        </div>
      </div>

      {/* Right Column */}
      <div className="flex flex-col gap-4 items-center justify-center z-10 px-6 md:px-10">
        <img src={TeamSpecsBg} alt="Team Specs" className="" />
      </div>
    </div>
  );
}
