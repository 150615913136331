import FullWidthBanner from "../components/Full-width-page-banner";
import ServicesBannerImage from "../assets/images/main/our-services-banner.png";
import Showroom from "../components/Showroom";

export default function ShowroomServicePage() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 bg-inputFill">
      <FullWidthBanner
        image={ServicesBannerImage}
        routing={"Our Services"}
        subRoute="showroom"
        LinkRoute={
          <>
            <p className="flex gap-2 font-dmSans font-normal text-md text-grayFill">
              Home
              <span className="text-primary-red">/</span>
              <span className="text-primaryRoute font-medium">
                Our Services{" "}
              </span>
            </p>
          </>
        }
      />
      <div className="py-4 md:py-10">
        <Showroom />
      </div>
    </div>
  );
}
