import { useState } from "react";
import { Link } from "react-router-dom";
import Tech from "../assets/images/home/tech.png";
import Oil from "../assets/images/home/oil.png";
import Trade from "../assets/images/home/logistics.png";
import { Button } from "./ui/button";
import { EyeInCircleIcon, HalfCircle } from "../components/icons/Social-media";

const data: BusinessCardsProps[] = [
  {
    id: 1,
    title: "Trade and Commerce:",
    description: "Professionals adept at handling international",
    image: Trade,
    link: "/",
  },
  {
    id: 2,
    title: "Oil & Gas:",
    description: "Strategic experts with deep understanding",
    image: Oil,
    link: "/",
  },
  {
    id: 3,
    title: "Technology and AI:",
    description: "Innovators in technology and artificial Intelligence",
    image: Tech,
    link: "/",
  },
];
export default function BusinessCards() {
  const [fillColors, setFillColors] = useState({
    PrimeFillColor: "#F64A00",
    SecFillColor: "#FFFFFF",
  });
  return (
    <div className="w-full flex flex-col justify-center items-center gap-4 relative py-10 pt-40 mb-20 bg-business-cards bg-cover bg-no-repeat">
      <div className="w-full px-4 md:px-0 md:w-9/12 flex flex-col justify-center items-center md:items-start">
        <h4 className="text-lg font-dmSans font-bold text-primaryOrange tracking-widest uppercase">
          recent case studies
        </h4>
        <div className="w-full flex flex-col md:flex-row justify-center md:justify-between items-center gap-2">
          <h2 className="font-redHat text-2xl md:text-5xl text-primaryRoute font-bold uppercase">
            our business cases
          </h2>
          <Button size={"lg"} variant={"default"} className="bg-primaryOrange">
            View All Projects
          </Button>
        </div>
      </div>
      <div className="w-full md:w-11/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8 px-4">
        {data.map((item: BusinessCardsProps) => (
          <div
            key={item.id}
            className="relative group overflow-hidden rounded-2xl"
          >
            <img
              src={item.image}
              alt={item.title}
              className="object-contain rounded-2xl transition-transform duration-300 group-hover:scale-110"
            />
            <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
              <Link
                className="z-20 cursor-pointer"
                to={item.link}
                onMouseEnter={() =>
                  setFillColors({
                    PrimeFillColor: "#FFFFFF",
                    SecFillColor: "#DF0A0A",
                  })
                }
                onMouseLeave={() =>
                  setFillColors({
                    PrimeFillColor: "#F64A00",
                    SecFillColor: "#FFFFFF",
                  })
                }
              >
                <EyeInCircleIcon
                  PrimeFillColor={fillColors.PrimeFillColor}
                  SecFillColor={fillColors.SecFillColor}
                />
              </Link>
            </div>
            <div className="absolute cursor-pointer inset-0 flex items-end justify-center lg:pb-10 opacity-0 group-hover:opacity-100 transition-opacity">
              <div className="rounded-lg bg-white flex flex-col items-start justify-center w-full md:w-auto p-4 lg:p-8 relative">
                <h2 className="text-left text-lg lg:text-xl font-bold font-redHat">
                  {item.title}
                </h2>
                <p className="font-dmSans text-md text-grayFill font-normal text-left">
                  {item.description}
                </p>
                <div className="absolute bottom-0 left-0">
                  <HalfCircle FillColor="#F64A00" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
