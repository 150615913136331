import { useState } from "react";
import NarrowHeader from "../layouts/Narrow-header";
import { pictures } from "../constants/pictures";
import PopoverMenu from "./forms/Popover";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Link } from "react-router-dom";
import LinkAsButton from "./ui/linkAsButton";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  InstagramIcon,
} from "../components/icons/Social-media";
import { useAboutData } from '../context/companyDataContext';
import XinhauLogo from "../assets/icons/xinhua-logo.png"

const baseURL = process.env.REACT_APP_BASE_URL;

const routes = [
  {
    id: 1,
    title: "Showroom",
    href: "/showroom-service"
  },
  {
    id: 2,
    title: "Other",
    href: "/other-services"
  }
];

export default function PagesHeader() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const aboutData = useAboutData();

  return (
    <>
      <NarrowHeader />
      <header className="py-2 lg:px-4 lg:py-10 text-black w-full flex justify-center items-center">
        <div className="flex flex-wrap justify-between items-center max-w-[1280px] px-4 lg:px-0 w-full">
          {aboutData?.status ? (
            <img src={`${baseURL}/${aboutData?.website_logo}`} alt="Xinhau Logo" />
          ) : (
            <img src={XinhauLogo} alt="Xinhau Logo" />
          )}
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>
          </div>
          <nav aria-label="Global" className="hidden lg:block">
            <div className="flex flex-wrap justify-center items-center gap-4 font-redHat text-lg font-medium">
              <Link to="/" className="mr-4 whitespace-nowrap text-lg">
                Home
              </Link>
              <Link to="/about" className="mr-4 whitespace-nowrap">
                About Us
              </Link>
              <PopoverMenu
                title={"Services"}
                routes={routes}
              />
              <Link to="/our-commitment" className="mr-4 whitespace-nowrap">
                Our Commitment
              </Link>
              <Link to="/our-team" className="mr-4 whitespace-nowrap">
                Our Team
              </Link>
              <LinkAsButton text={"CONTACT US"} to={"/contact"} variant={"primary"} className="hover:text-primaryOrange" />
            </div>
          </nav>
          <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
            <div className="fixed inset-0 z-10" />
            <DialogPanel className="fixed  flex flex-col justify-between gap-4 inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div>
                <div className="flex items-center justify-between">
                  <Link to="/" className="-m-1.5 p-1.5">
                    <span className="sr-only">Xinhau</span>
                    {aboutData?.status ? (
                      <img src={`${baseURL}/${aboutData?.website_logo}`} alt="Xinhau Logo" />
                    ) : (
                      <img src={XinhauLogo} alt="Xinhau Logo" />
                    )}
                  </Link>
                  <button
                    type="button"
                    onClick={() => setMobileMenuOpen(false)}
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon aria-hidden="true" className="size-6" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                      <Link
                        to="/about"
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 font-redHat text-lg font-medium text-black hover:bg-gray-50"
                      >
                        About Us
                      </Link>
                      <Disclosure as="div" className="-mx-3">
                        <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-gray-900 hover:bg-gray-50">
                          <span className="font-redHat font-medium text-lg">
                            Services
                          </span>
                          <ChevronDownIcon aria-hidden="true" className="size-5 flex-none group-data-[open]:rotate-180" />
                        </DisclosureButton>
                        <DisclosurePanel className="mt-2 space-y-2">
                          {routes.map((item) => (
                            <DisclosureButton
                              onClick={() => setMobileMenuOpen(false)}
                              key={item.id}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 font-redHat text-lg font-medium text-black hover:bg-gray-50"
                            >
                              {item.title}
                            </DisclosureButton>
                          ))}
                        </DisclosurePanel>
                      </Disclosure>

                      <Link
                        to="/our-commitment"
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 font-redHat text-lg font-medium text-black hover:bg-gray-50"
                      >
                        Our Commitment
                      </Link>
                      <Link
                        to="/our-team"
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 font-redHat text-lg font-medium text-black hover:bg-gray-50"
                      >
                        Our Team
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-black text-md font-medium font-redHat flex flex-col gap-4 justify-center items-center">
                <div className="w-full h-2 border-t-2 border-gray-300" />
                <span>
                  <b>Email:</b> Info@xinhuaage.com
                </span>
                <span>
                  <b>Working:</b> 9.00am - 5.00pm
                </span>
                <div className="flex flex-row justify-center items-center gap-4">
                  <Link to={"/"} target="_blank">
                    {" "}
                    <FacebookIcon FillColor={"#DF0A0A"} />
                  </Link>
                  <Link to={"/"} target="_blank">
                    {" "}
                    <TwitterIcon FillColor={"#DF0A0A"} />
                  </Link>
                  <Link to={"/"} target="_blank">
                    {" "}
                    <LinkedinIcon FillColor={"#DF0A0A"} />
                  </Link>
                  <Link to={"/"} target="_blank">
                    {" "}
                    <InstagramIcon FillColor={"#DF0A0A"} />
                  </Link>
                </div>
              </div>
            </DialogPanel>
          </Dialog>
        </div>
      </header>
    </>
  );
}
