import React, { createContext, useContext, useState, useEffect } from 'react';
import { StaticIcons } from "../components/icons/static-icons.js";
import XINHUALOGO from "./logo.png";
const baseURL = process.env.REACT_APP_BASE_URL;

const defaultAboutData: AboutData = {
    id: 1,
    website_name: "XINHUA AGE",
    website_description: "International Trade and Development",
    website_logo: XINHUALOGO,
    phone_numbers: {
        numbers: ["+1234567890", "+97433605606"],
        primary: "+97433605606",
    },
    emails: {
        emails: ["Info@xinhuaage.com"],
        primary: "Info@xinhuaage.com",
    },
    locations: {
        locations: ["UDC Tower, the pearl, blvrd , 4th floor,Doha, QA"],
        primary: "UDC Tower, the pearl, blvrd , 4th floor,Doha, QA",
    },
    created_at: "2024-11-25T11:35:47.000000Z",
    updated_at: "2024-12-02T12:02:45.000000Z",
    status: false
};

const defaultSocialMedia: SocialMediaItem[] = [
    {
        id: 1,
        icon: StaticIcons.Facebook,
        name: "Facebook",
        link: "https://www.facebook.com",
        created_at: "2024-11-25T11:40:26.000000Z",
        updated_at: "2024-12-02T12:02:59.000000Z",
        status: false
    },
    {
        id: 2,
        icon: StaticIcons.Intsa,
        name: "Instagram",
        link: "https://www.instagram.com",
        created_at: "2024-11-25T11:40:26.000000Z",
        updated_at: "2024-12-02T12:02:59.000000Z",
        status: false
    },
    {
        id: 3,
        icon: StaticIcons.Linkedin,
        name: "LinkednIn",
        link: "https://linkedin.com",
        created_at: "2024-11-25T11:40:26.000000Z",
        updated_at: "2024-12-02T12:02:59.000000Z",
        status: false
    },
    {
        id: 4,
        icon: StaticIcons.X,
        name: "X",
        link: "https://x.com",
        created_at: "2024-12-02T12:04:12.000000Z",
        updated_at: "2024-12-02T12:17:33.000000Z",
        status: false
    },
];

const AboutDataContext = createContext<AboutData | null>(null);
const SocialMediaContext = createContext<SocialMediaItem[] | null>(null);

export const AboutDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [aboutData, setAboutData] = useState<AboutData>(defaultAboutData);

    useEffect(() => {
        fetch(`${baseURL}/api/about`)
            .then((response) => response.json())
            .then((data) => {
                const about = data.about;
                setAboutData({
                    ...about,
                    phone_numbers: JSON.parse(about.phone_numbers),
                    emails: JSON.parse(about.emails),
                    locations: JSON.parse(about.locations),
                });
            })
            .catch((error) => console.error('Error fetching about data:', error));
    }, []);

    return (
        <AboutDataContext.Provider value={aboutData}>
            {children}
        </AboutDataContext.Provider>
    );
};

export const SocialMediaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [socialMedia, setSocialMedia] = useState<SocialMediaItem[]>(defaultSocialMedia);

    useEffect(() => {
        fetch(`${baseURL}/api/socialMedia`)
            .then((response) => response.json())
            .then((data: SocialMediaResponse) => {
                if (data.status) {
                    setSocialMedia(data.social_media);
                }
            })
            .catch((error) => console.error('Error fetching social media data:', error));
    }, []);

    return (
        <SocialMediaContext.Provider value={socialMedia}>
            {children}
        </SocialMediaContext.Provider>
    );
};

export const useAboutData = () => useContext(AboutDataContext);
export const useSocialMediaData = () => useContext(SocialMediaContext);
