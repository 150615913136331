import React from "react";
import Newsletter from "./Newsletter";
import { contactIcons } from "../constants/icons";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";
import { ArrowIcon } from "./icons/icons";
import { pictures } from "../constants/pictures";
import { useAboutData } from '../context/companyDataContext';

type ContactItemProps = {
  image: string;
  headText: string;
  text: any;
  type: string;
};

export default function PagesFooter() {
  const aboutData = useAboutData();
  const phoneNumbers = aboutData?.phone_numbers;
  const emails = aboutData?.emails.primary;
  const location = aboutData?.locations.primary;

  const HeaderFooter = ({ text }: { text: string }) => {
    return (
      <div className=" text-white py-3">
        <p className="text-xl font-bold font-redHat capitalize">{text}</p>
        <div className="mt-2 flex items-center space-x-2">
          <div className="w-16 h-0.5 bg-white"></div>
          <div className="w-6 h-0.5 bg-white"></div>
        </div>
      </div>
    );
  };

  const contact = [
    {
      id: 1,
      type: "phone",
      image: contactIcons.phone,
      headText: "Call Us 24/7",
      text: phoneNumbers?.numbers || [],
    },
    {
      id: 2,
      type: "mail",
      image: contactIcons.mail,
      headText: "Work with us",
      text: emails,
    },
    {
      id: 3,
      type: "location",
      image: contactIcons.location,
      headText: "Our Location",
      text: location,
    },
  ];

  const ContactItem = ({ type, image, headText, text }: ContactItemProps) => {
    return (
      <div className="flex flex-row gap-4">
        {/* Icon Section */}
        <div className="flex items-center justify-center bg-white w-10 h-10 rounded-full">
          <img src={image} alt={`${type} icon`} className="w-5 h-5" />
        </div>

        {/* Text Section */}
        <div className="flex flex-col gap-1">
          <p>{headText}</p>

          {type === "phone" && Array.isArray(text) ? (
            text.map((phone: string, index: number) => (
              <a
                key={index}
                className="font-semibold max-w-56 text-sm hover:text-primaryOrange"
                href={`tel:${phone}`}
              >
                {phone}
              </a>
            ))
          ) : type === "mail" ? (
            <a
              className="font-semibold max-w-56 text-sm hover:text-[#f64a00]"
              href={`mailto:${text}`}
            >
              {text}
            </a>
          ) : (
            <p className="font-semibold max-w-56 text-sm">{text}</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="relative flex flex-col items-center bg-[#0e1423] md:py-10 overflow-hidden">
      <img
        src={pictures.FooterBubbles}
        className="absolute w-full h-full hidden md:block"
      />
      <Newsletter />
      <div className="flex flex-col md:flex-row justify-around items-center md:items-start gap-4 py-8 md:py-20 mb-10 text-white w-full">
        <div className="w-full md:w-auto flex flex-col justify-start items-center md:items-center px-8 z-20">
          <HeaderFooter text="quick links" />
          <div className="flex flex-col gap-4 text-[#8B8F99]">
            <Link to="/about" className="flex flex-row gap-2">
              <ArrowIcon FillColor="#8B8F99" />
              About Us
            </Link>
            <Link to="/contact" className="flex flex-row gap-2">
              <ArrowIcon FillColor="#8B8F99" />
              Contact Us
            </Link>
            <Link to="/our-team" className="flex flex-row gap-2">
              <ArrowIcon FillColor="#8B8F99" />
              Our Team
            </Link>
            <Link to="/showroom-service" className="flex flex-row gap-2">
              <ArrowIcon FillColor="#8B8F99" />
              Our Services
            </Link>
          </div>
        </div>
        <div className="w-full md:w-auto bg-gradient-to-t from-[#212631] to-transparent px-8 py-4 rounded-lg">
          <HeaderFooter text="working hours" />
          <div className="flex flex-col gap-4">
            <div className="mt-4 space-y-4">
              <div className="flex flex-row gap-4 justify-between">
                <span>Sunday - Thursday</span>
                <span>08.00 - 05:00</span>
              </div>
              <div className="flex justify-between">
                <span>Saturday</span>
                <span>09:00 - 03.00</span>
              </div>
              <div className="flex justify-between">
                <span>Friday</span>
                <span>Day Off</span>
              </div>
            </div>
            <Button size={"lg"} variant={"default"}>
              Contact Us
            </Button>
          </div>
        </div>
        <div className="z-20">
          <HeaderFooter text="office inforamtion" />
          <div className="flex flex-col gap-4">
            {contact.map((contact) => {
              return (
                <div key={contact.id}>
                  <ContactItem
                    image={contact.image}
                    headText={contact.headText}
                    text={contact.text}
                    type={contact.type}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="absolute flex flex-col gap-2 justify-center items-center bottom-0 my-4 text-white w-full">
        <div className="w-full h-2 border-t-2 border-[#21293D]" />
        <span className="font-dmSans font-medium text-md ">&copy;2024 All rights reserved</span>
      </div>
    </div>
  );
}
