
const TradeData: TradeDataProps[] = [
  {
    id: 1,
    title: "oil & gas",
  },
  {
    id: 2,
    title: "petrochemicals",
  },
  {
    id: 3,
    title: "soft commodities",
  },
  {
    id: 4,
    title: "food commodities",
  },
  {
    id: 5,
    title: "raw materials",
  },
  {
    id: 6,
    title: "pro services",
  },
  {
    id: 7,
    title: "financial and managerial services",
  },
];

export default function OtherServicesPage() {
  return (
    <div className="w-full py-10 flex justify-center items-center">
      <div className="w-10/12 flex flex-col gap-8 lg:gap-10">
        <div className="bg-other-services bg-contain md:bg-cover lg:bg-cover bg-no-repeat bg-center min-h-[8rem] md:min-h-[10rem] lg:min-h-[31.25rem]" />
        <div className="flex flex-col gap-6 justify-center items-start">
          <h2 className="text-primary-red font-redHat text-3xl md:text-4xl font-bold capitalize text-left">
            consultancy & management services
          </h2>
          <div className="px-2">
            <p className="text-grayFill text-lg font-normal font-dmSans">
              Xinhua Age Trading brings together a team of seasoned business
              professionals with extensive experience in both the Qatari and
              Chinese markets. We provide comprehensive consultancy services
              covering:
            </p>
            <ul className="text-grayFill text-lg font-normal font-dmSans list-disc px-8 my-4">
              <li>
                <i>
                  Financial Management: Expert advice on financial planning and
                  investment strategies.
                </i>
              </li>
              <li>
                <i>
                  Business Development: Tailored solutions to accelerate growth.
                </i>
              </li>
              <li>
                <i>
                  Strategic Planning: Detailed planning to ensure long-term
                  success.
                </i>
              </li>
            </ul>

            <p className="text-grayFill text-lg font-normal font-dmSans">
              Whether you are a tenant in our showroom or an independent client,
              our tailored solutions are designed to meet your specific needs
              and drive your success in the GCC market.
            </p>
          </div>
        </div>

        <div className="mt-2 md:mt-8 flex flex-col gap-4 md:gap-8">
          <h2 className="text-primary-red font-montserrat text-4xl md:text-5xl font-extrabold capitalize text-nowrap">
            Trade Desk
          </h2>
          <p className="text-grayFill text-lg font-normal font-dmSans">
            Our trade desk in Doha is equipped to handle a wide range of
            commodities, including:
          </p>
          <div className="w-fit flex flex-col justify-center items-center gap-2 bg-inputFill px-6 py-6">
            {TradeData.map((item: TradeDataProps) => (
              <div key={item.id} className="bg-white w-full p-3">
                <p className="font-dmSans text-lg text-grayFill capitalize">
                  {item.title}
                </p>
              </div>
            ))}
          </div>
          <p className="text-grayFill text-lg font-normal font-dmSans">
            We facilitate secure and efficient trade transactions, ensuring that
            Chinese companies can source their needs from the GCC and
            international markets. Conversely, we also act as a conduit for
            Chinese goods and services entering Qatar and the broader GCC
            region.
          </p>
        </div>
      </div>
    </div>
  );
}
