export default function FullWidthBanner({
  image,
  routing,
  LinkRoute,
  subRoute,
}: FullWidthBannerProps): JSX.Element {
  return (
    <div className="relative flex flex-col justify-center items-center">
      <img
        src={image}
        alt="Work Together Background"
        className="object-cover min-h-[20rem] md:min-h-[15rem] lg:min-h-[10rem] w-full"
      />
      <div className="absolute w-full px-10 flex flex-row flex-wrap justify-around items-center gap-10 z-10 top-1/2 transform -translate-y-1/2">
        {subRoute ? (
          <h3 className="flex flex-col gap-2 text-4xl md:text-6xl font-redHat font-bold text-white capitalize tracking-normal text-center md:text-left px-4">
            {routing}
            <span className="text-primary-red pl-4 capitalize font-redHat text-lg font-medium  ">
              {subRoute}
            </span>
          </h3>
        ) : (
          <h3 className="text-4xl md:text-6xl font-redHat font-bold text-white capitalize tracking-normal text-center px-4">
            {routing}
          </h3>
        )}
        <div className="bg-white rounded-full px-4 py-2 font-medium">
          {LinkRoute}
        </div>
      </div>
    </div>
  );
}
