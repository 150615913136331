"use client";

import { useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Send } from "lucide-react";

export default function InputwithButton() {
  const [email, setEmail] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Email submitted:", email);
    setEmail("");
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full max-w-lg flex justify-center px-3"
    >
      <Input
        type="email"
        placeholder="Enter Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="rounded-full bg-white shadow-sm border-0 py-8 placeholder:font-dmSans placeholder:text-muted-foreground/60"
        required
      />
      <Button
        type="submit"
        size="icon"
        className="rounded-full bg-[#df0a0a] w-5/12 md:w-1/3 p-8 -ml-20"
      >
        <span className="capitalize font-dmSans font-bold">subscribe now</span>
      </Button>
    </form>
  );
}
