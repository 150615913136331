import Hero from "../components/Hero";
import Culture from "../components/Culture";
import WhyChooseUs from "../components/Why-choose-us";
import ValuesSection from "../components/Mission-vision-section";
import BusinessCards from "../components/Business-cards";
import OurFeatures from "../components/OurFeatures";
import WideWorkTogether from "../components/Wide-work-together";

export default function Home() {
  return (
    <div>
      <Hero />
      <Culture />
      <OurFeatures />
      <WideWorkTogether />
      <ValuesSection />
      <BusinessCards />
      <WhyChooseUs />
    </div>
  );
}
