export function FacebookIcon({ FillColor, className }: IconProps) {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill={FillColor || "none"}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M7.62891 8.6355L8.01172 6.11987H5.57812V4.47925C5.57812 3.76831 5.90625 3.11206 7 3.11206H8.12109V0.951904C8.12109 0.951904 7.10938 0.760498 6.15234 0.760498C4.15625 0.760498 2.84375 1.99097 2.84375 4.17847V6.11987H0.601562V8.6355H2.84375V14.7605H5.57812V8.6355H7.62891Z" />
    </svg>
  );
}

export function InstagramIcon({
  FillColor,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill={FillColor || "none"}
      xmlns="http://www.w3.org/2000/svg"
      className={` ${className}`}
    >
      <path d="M7.125 3.61597C5.375 3.61597 3.98047 5.03784 3.98047 6.7605C3.98047 8.5105 5.375 9.90503 7.125 9.90503C8.84766 9.90503 10.2695 8.5105 10.2695 6.7605C10.2695 5.03784 8.84766 3.61597 7.125 3.61597ZM7.125 8.81128C6.00391 8.81128 5.07422 7.90894 5.07422 6.7605C5.07422 5.6394 5.97656 4.73706 7.125 4.73706C8.24609 4.73706 9.14844 5.6394 9.14844 6.7605C9.14844 7.90894 8.24609 8.81128 7.125 8.81128ZM11.1172 3.50659C11.1172 3.91675 10.7891 4.24487 10.3789 4.24487C9.96875 4.24487 9.64062 3.91675 9.64062 3.50659C9.64062 3.09644 9.96875 2.76831 10.3789 2.76831C10.7891 2.76831 11.1172 3.09644 11.1172 3.50659ZM13.1953 4.24487C13.1406 3.2605 12.9219 2.3855 12.2109 1.67456C11.5 0.963623 10.625 0.744873 9.64062 0.690186C8.62891 0.635498 5.59375 0.635498 4.58203 0.690186C3.59766 0.744873 2.75 0.963623 2.01172 1.67456C1.30078 2.3855 1.08203 3.2605 1.02734 4.24487C0.972656 5.25659 0.972656 8.29175 1.02734 9.30347C1.08203 10.2878 1.30078 11.1355 2.01172 11.8738C2.75 12.5847 3.59766 12.8035 4.58203 12.8582C5.59375 12.9128 8.62891 12.9128 9.64062 12.8582C10.625 12.8035 11.5 12.5847 12.2109 11.8738C12.9219 11.1355 13.1406 10.2878 13.1953 9.30347C13.25 8.29175 13.25 5.25659 13.1953 4.24487ZM11.8828 10.3699C11.6914 10.9167 11.2539 11.3269 10.7344 11.5457C9.91406 11.8738 8 11.7917 7.125 11.7917C6.22266 11.7917 4.30859 11.8738 3.51562 11.5457C2.96875 11.3269 2.55859 10.9167 2.33984 10.3699C2.01172 9.5769 2.09375 7.66284 2.09375 6.7605C2.09375 5.8855 2.01172 3.97144 2.33984 3.15112C2.55859 2.63159 2.96875 2.22144 3.51562 2.00269C4.30859 1.67456 6.22266 1.75659 7.125 1.75659C8 1.75659 9.91406 1.67456 10.7344 2.00269C11.2539 2.19409 11.6641 2.63159 11.8828 3.15112C12.2109 3.97144 12.1289 5.8855 12.1289 6.7605C12.1289 7.66284 12.2109 9.5769 11.8828 10.3699Z" />
    </svg>
  );
}
export function LinkedinIcon({ FillColor, className }: IconProps): JSX.Element {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill={FillColor || "none"}
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path d="M2.73438 13.0105H0.191406V4.83472H2.73438V13.0105ZM1.44922 3.74097C0.65625 3.74097 0 3.05737 0 2.23706C0 1.11597 1.20312 0.405029 2.1875 0.979248C2.65234 1.22534 2.92578 1.71753 2.92578 2.23706C2.92578 3.05737 2.26953 3.74097 1.44922 3.74097ZM12.2227 13.0105H9.70703V9.04565C9.70703 8.08862 9.67969 6.8855 8.36719 6.8855C7.05469 6.8855 6.86328 7.89722 6.86328 8.96362V13.0105H4.32031V4.83472H6.75391V5.95581H6.78125C7.13672 5.3269 7.95703 4.64331 9.1875 4.64331C11.7578 4.64331 12.25 6.33862 12.25 8.52612V13.0105H12.2227Z" />
    </svg>
  );
}

export function SkypeIcon({ FillColor, className }: IconProps): JSX.Element {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill={FillColor || "none"}
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path d="M11.5938 7.96362C12.0039 8.48315 12.25 9.1394 12.2227 9.823C12.2227 11.5183 10.8555 12.8855 9.16016 12.8855C8.47656 12.8855 7.82031 12.6667 7.30078 12.2566C6.91797 12.3386 6.50781 12.3933 6.09766 12.3933C3.00781 12.3933 0.492188 9.87769 0.492188 6.7605C0.492188 6.37769 0.546875 5.96753 0.628906 5.58472C0.21875 5.06519 0 4.40894 0 3.698C0 2.03003 1.36719 0.635498 3.0625 0.635498C3.74609 0.635498 4.40234 0.881592 4.92188 1.29175C5.30469 1.20972 5.71484 1.15503 6.125 1.15503C9.21484 1.15503 11.7305 3.67065 11.7305 6.7605C11.7305 7.17065 11.6758 7.58081 11.5938 7.96362ZM6.28906 10.4792C7.79297 10.4792 9.35156 9.71362 9.35156 8.10034C9.35156 6.70581 7.98438 6.24097 6.86328 5.99487C5.55078 5.66675 4.97656 5.61206 4.97656 5.09253C4.97656 4.65503 5.41406 4.46362 6.125 4.46362C7.38281 4.46362 7.41016 5.39331 8.17578 5.39331C8.69531 5.39331 8.99609 4.98315 8.99609 4.51831C8.99609 3.58862 7.46484 2.98706 5.96094 2.98706C4.59375 2.98706 2.98047 3.58862 2.98047 5.22925C2.98047 7.00659 4.51172 7.17065 6.20703 7.60815C6.91797 7.77222 7.35547 7.85425 7.35547 8.37378C7.35547 8.78394 6.89062 9.08472 6.20703 9.08472C4.73047 9.08472 4.64844 7.85425 3.80078 7.85425C3.22656 7.85425 2.98047 8.2644 2.98047 8.7019C2.98047 9.68628 4.48438 10.4792 6.28906 10.4792Z" />
    </svg>
  );
}

export function TwitterIcon({ FillColor, className }: IconProps): JSX.Element {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill={FillColor || "none"}
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path d="M12.5508 2.91675C12.5508 3.05347 12.5508 3.16284 12.5508 3.29956C12.5508 7.10034 9.67969 11.448 4.40234 11.448C2.76172 11.448 1.25781 10.9832 0 10.1628C0.21875 10.1902 0.4375 10.2175 0.683594 10.2175C2.02344 10.2175 3.25391 9.75269 4.23828 8.98706C2.98047 8.95972 1.91406 8.1394 1.55859 6.99097C1.75 7.01831 1.91406 7.04565 2.10547 7.04565C2.35156 7.04565 2.625 6.99097 2.84375 6.93628C1.53125 6.66284 0.546875 5.5144 0.546875 4.11987V4.09253C0.929688 4.31128 1.39453 4.42065 1.85938 4.448C1.06641 3.92847 0.574219 3.05347 0.574219 2.06909C0.574219 1.52222 0.710938 1.03003 0.957031 0.619873C2.37891 2.34253 4.51172 3.49097 6.89062 3.62769C6.83594 3.40894 6.80859 3.19019 6.80859 2.97144C6.80859 1.3855 8.09375 0.100342 9.67969 0.100342C10.5 0.100342 11.2383 0.428467 11.7852 1.00269C12.4141 0.865967 13.043 0.619873 13.5898 0.291748C13.3711 0.975342 12.9336 1.52222 12.332 1.87769C12.9062 1.823 13.4805 1.65894 13.9727 1.44019C13.5898 2.0144 13.0977 2.50659 12.5508 2.91675Z" />
    </svg>
  );
}

export function PhoneCallIcon({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9727 11.3427L13.3164 14.1044C13.2344 14.5145 12.9062 14.788 12.4961 14.788C5.60547 14.7606 0 9.15516 0 2.26453C0 1.85438 0.246094 1.52625 0.65625 1.44422L3.41797 0.787972C3.80078 0.705941 4.21094 0.924691 4.375 1.28016L5.66016 4.26063C5.79688 4.6161 5.71484 5.02625 5.41406 5.245L3.9375 6.44813C4.86719 8.33485 6.39844 9.8661 8.3125 10.7958L9.51562 9.31922C9.73438 9.04578 10.1445 8.93641 10.5 9.07313L13.4805 10.3583C13.8359 10.5497 14.0547 10.9598 13.9727 11.3427Z"
        fill={FillColor}
      />
    </svg>
  );
}
export function SignalPhoneIcon({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9838 9.7461C11.586 9.79029 11.2988 10.1659 11.3209 10.5858C11.365 10.9835 11.7186 11.2929 12.1605 11.2487C12.6025 11.2045 13.0444 11.3371 13.3538 11.6464C13.6852 11.9779 13.8178 12.4198 13.7736 12.8618C13.7294 13.3037 14.0388 13.6573 14.4365 13.7015C14.6796 13.7236 14.9006 13.6352 15.0553 13.4805C15.1657 13.37 15.2541 13.1932 15.2762 13.0386C15.3646 12.1105 15.0553 11.2266 14.4144 10.5858C13.7957 9.96707 12.9118 9.65771 11.9838 9.7461ZM11.6965 6.76299C11.2546 6.80718 10.9673 7.18284 11.0115 7.58058C11.0336 8.00043 11.4092 8.28769 11.8291 8.26559C13.1991 8.13301 14.5028 8.59705 15.453 9.54722C16.3811 10.4753 16.8672 11.8011 16.7346 13.1711C16.6904 13.5689 16.9777 13.9445 17.3976 13.9666C17.6406 13.9887 17.8395 13.9224 17.9942 13.7678C18.1268 13.6352 18.193 13.4805 18.2372 13.3037C18.4361 11.5139 17.8174 9.7461 16.5358 8.46447C15.2541 7.18284 13.5085 6.58621 11.6965 6.76299ZM11.4092 3.77988C10.9894 3.80198 10.7021 4.17763 10.7242 4.59748C10.7684 4.99522 11.122 5.30458 11.5418 5.28249C13.7957 5.06151 16.0054 5.81282 17.5964 7.40381C19.1653 8.9727 19.9387 11.2045 19.7177 13.4584C19.6956 13.8783 20.005 14.2318 20.4028 14.276C20.6458 14.2981 20.8668 14.2097 21.0215 14.055C21.132 13.9445 21.1983 13.7899 21.2203 13.591C21.5076 10.9172 20.5795 8.26559 18.6571 6.34315C16.7125 4.3986 14.1051 3.51472 11.4092 3.77988ZM8.89017 12.5303C9.13324 12.3315 9.24373 11.9558 9.11114 11.6464L7.98419 9.06109C7.85161 8.70753 7.45386 8.53076 7.12241 8.59705L4.69173 9.17157C4.36027 9.23786 4.1172 9.56932 4.1172 9.92287C4.1172 15.9775 9.04485 20.9051 15.0995 20.9051C15.453 20.9051 15.7845 20.6621 15.8508 20.3306L16.4253 17.8999C16.4916 17.5685 16.3148 17.1707 15.9612 17.0381L13.3759 15.9112C13.0665 15.7786 12.6909 15.8891 12.492 16.1322L11.4313 17.4138C9.75196 16.6183 8.40404 15.2704 7.60854 13.591L8.89017 12.5303Z"
        fill={FillColor}
      />
    </svg>
  );
}
export function RightArrow({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16px"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={FillColor}
    >
      <path
        d="M3 12L21 12M21 12L12.5 3.5M21 12L12.5 20.5"
        stroke={FillColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
}

export function MailIcon({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6875 0.510742C13.3984 0.510742 14 1.1123 14 1.82324C14 2.26074 13.7812 2.64355 13.4531 2.88965L7.51953 7.34668C7.19141 7.59277 6.78125 7.59277 6.45312 7.34668L0.519531 2.88965C0.191406 2.64355 0 2.26074 0 1.82324C0 1.1123 0.574219 0.510742 1.3125 0.510742H12.6875ZM5.93359 8.05762C6.5625 8.52246 7.41016 8.52246 8.03906 8.05762L14 3.57324V9.26074C14 10.2451 13.207 11.0107 12.25 11.0107H1.75C0.765625 11.0107 0 10.2451 0 9.26074V3.57324L5.93359 8.05762Z"
        fill={FillColor}
      />
    </svg>
  );
}

export function LocationPinIcon({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="11"
      height="15"
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59375 14.4326C3.17188 12.6553 0 8.41699 0 6.01074C0 3.1123 2.32422 0.760742 5.25 0.760742C8.14844 0.760742 10.5 3.1123 10.5 6.01074C10.5 8.41699 7.30078 12.6553 5.87891 14.4326C5.55078 14.8428 4.92188 14.8428 4.59375 14.4326ZM5.25 7.76074C6.20703 7.76074 7 6.99512 7 6.01074C7 5.05371 6.20703 4.26074 5.25 4.26074C4.26562 4.26074 3.5 5.05371 3.5 6.01074C3.5 6.99512 4.26562 7.76074 5.25 7.76074Z"
        fill={FillColor}
      />
    </svg>
  );
}

export function ClockIcon({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.34375 4.03125C6.34375 3.67578 6.61719 3.375 7 3.375C7.35547 3.375 7.65625 3.67578 7.65625 4.03125V7.42188L9.98047 8.95312C10.2812 9.17188 10.3633 9.58203 10.1445 9.88281C9.95312 10.1836 9.54297 10.2656 9.24219 10.0469L6.61719 8.29688C6.45312 8.1875 6.34375 7.96875 6.34375 7.75V4.03125ZM7 0.75C10.8555 0.75 14 3.89453 14 7.75C14 11.6328 10.8555 14.75 7 14.75C3.11719 14.75 0 11.6328 0 7.75C0 3.89453 3.11719 0.75 7 0.75ZM1.3125 7.75C1.3125 10.8945 3.85547 13.4375 7 13.4375C10.1172 13.4375 12.6875 10.8945 12.6875 7.75C12.6875 4.63281 10.1172 2.0625 7 2.0625C3.85547 2.0625 1.3125 4.63281 1.3125 7.75Z"
        fill={FillColor}
      />
    </svg>
  );
}

export function EyeInCircleIcon({
  PrimeFillColor,
  SecFillColor = "none",
}: {
  PrimeFillColor: string;
  SecFillColor?: string;
}): JSX.Element {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.5" cy="27.5" r="27.5" fill={SecFillColor} />
      <path
        d="M24 28C24 25.8125 25.7812 24 28 24C30.1875 24 32 25.8125 32 28C32 30.2188 30.1875 32 28 32C25.7812 32 24 30.2188 24 28ZM28 30.5C29.375 30.5 30.5 29.4062 30.5 28C30.5 26.625 29.375 25.5 28 25.5C27.9688 25.5 27.9375 25.5 27.9062 25.5C27.9688 25.6875 28 25.8438 28 26C28 27.125 27.0938 28 26 28C25.8125 28 25.6562 28 25.5 27.9375C25.5 27.9688 25.5 28 25.5 28C25.5 29.4062 26.5938 30.5 28 30.5ZM21.9688 23.5312C23.4375 22.1562 25.4688 21 28 21C30.5 21 32.5312 22.1562 34 23.5312C35.4688 24.875 36.4375 26.5 36.9062 27.625C37 27.875 37 28.1562 36.9062 28.4062C36.4375 29.5 35.4688 31.125 34 32.5C32.5312 33.875 30.5 35 28 35C25.4688 35 23.4375 33.875 21.9688 32.5C20.5 31.125 19.5312 29.5 19.0625 28.4062C18.9688 28.1562 18.9688 27.875 19.0625 27.625C19.5312 26.5 20.5 24.875 21.9688 23.5312ZM28 22.5C25.9375 22.5 24.2812 23.4375 23 24.625C21.7812 25.75 20.9688 27.0625 20.5312 28C20.9688 28.9375 21.7812 30.2812 23 31.4062C24.2812 32.5938 25.9375 33.5 28 33.5C30.0312 33.5 31.6875 32.5938 32.9688 31.4062C34.1875 30.2812 35 28.9375 35.4375 28C35 27.0625 34.1875 25.75 32.9688 24.625C31.6875 23.4375 30.0312 22.5 28 22.5Z"
        fill={PrimeFillColor}
      />
    </svg>
  );
}

export function HalfCircle({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.1"
        cx="6"
        cy="29"
        r="29"
        transform="rotate(180 6 29)"
        fill="#F64A00"
      />
      <circle cy="35" r="23" transform="rotate(180 0 35)" fill={FillColor} />
    </svg>
  );
}

export function MapWithMarker({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="54"
      height="49"
      viewBox="0 0 54 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.75 10.5C24.75 9.28125 25.6875 8.25 27 8.25C28.2188 8.25 29.25 9.28125 29.25 10.5C29.25 11.8125 28.2188 12.75 27 12.75C25.6875 12.75 24.75 11.8125 24.75 10.5ZM25.5938 29.25C22.5938 25.5 15.75 16.4062 15.75 11.25C15.75 5.0625 20.7188 0 27 0C33.1875 0 38.25 5.0625 38.25 11.25C38.25 16.4062 31.3125 25.5 28.3125 29.25C27.6562 30.1875 26.25 30.1875 25.5938 29.25ZM34.3125 14.5312C34.9688 13.0312 35.25 12 35.25 11.25C35.25 6.75 31.5 3 27 3C22.4062 3 18.75 6.75 18.75 11.25C18.75 12 18.9375 13.0312 19.5938 14.5312C20.1562 16.0312 21.0938 17.625 22.0312 19.2188C23.625 21.8438 25.5 24.375 27 26.25C28.4062 24.375 30.2812 21.8438 31.875 19.2188C32.8125 17.625 33.75 16.0312 34.3125 14.5312ZM37.9688 20.9062C37.9688 21 37.875 21 37.7812 21C38.5312 19.6875 39.2812 18.2812 39.8438 16.9688L50.9062 12.5625C52.3125 12 54 13.0312 54 14.625V40.0312C54 40.9688 53.4375 41.8125 52.5 42.0938L37.9688 47.9062C37.6875 48.0938 37.4062 48.0938 37.0312 48L16.5 42.0938L3 47.5312C1.59375 48.0938 0 47.0625 0 45.4688V20.0625C0 19.125 0.46875 18.2812 1.40625 18L12.9375 13.3125C13.125 14.3438 13.4062 15.2812 13.7812 16.2188L3 20.5312V44.3438L15 39.5625V28.5C15 27.75 15.6562 27 16.5 27C17.25 27 18 27.75 18 28.5V39.375L36 44.5312V28.5C36 27.75 36.6562 27 37.5 27C38.25 27 39 27.75 39 28.5V44.3438L51 39.5625V15.75L37.9688 20.9062Z"
        fill={FillColor}
      />
    </svg>
  );
}

export function EnvelopeIcon({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.5 18C45.6562 18 45 18.75 45 19.5V43.5C45 44.3438 44.25 45 43.5 45H4.5C3.65625 45 3 44.3438 3 43.5V19.5C3 18.75 2.25 18 1.5 18C0.65625 18 0 18.75 0 19.5V43.5C0 46.0312 1.96875 48 4.5 48H43.5C45.9375 48 48 46.0312 48 43.5938V19.5C48 18.75 47.25 18 46.5 18ZM6 25.9688C6.09375 26.0625 6.09375 26.1562 6.09375 26.25C6.1875 26.3438 6.1875 26.3438 6.28125 26.4375C6.375 26.5312 6.375 26.625 6.46875 26.7188C6.5625 26.7188 6.5625 26.7188 6.5625 26.7188L19.0312 35.9062C20.4375 36.9375 22.125 37.5 24 37.5C25.7812 37.5 27.4688 36.9375 28.875 35.8125L41.3438 26.625C41.3438 26.625 41.3438 26.625 41.4375 26.625C41.5312 26.5312 41.5312 26.4375 41.625 26.3438C41.7188 26.25 41.7188 26.25 41.8125 26.1562C41.8125 26.0625 41.8125 25.9688 41.9062 25.875C41.9062 25.7812 41.9062 25.6875 41.9062 25.5938C41.9062 25.5938 42 25.5938 42 25.5V4.5C42 2.0625 39.9375 0 37.5 0H10.5C7.96875 0 6 2.0625 6 4.5V25.5C6 25.5938 6 25.5938 6 25.6875C6 25.7812 6 25.875 6 25.9688ZM9 4.5C9 3.75 9.65625 3 10.5 3H37.5C38.25 3 39 3.75 39 4.5V24.75L27.0938 33.4688C25.2188 34.875 22.6875 34.875 20.8125 33.4688L9 24.75V4.5ZM33 22.5C33 21.6562 32.25 21 31.5 21H16.5C15.6562 21 15 21.6562 15 22.5C15 23.25 15.6562 23.9062 16.5 23.9062H31.5C32.25 24 33 23.3438 33 22.5ZM16.5 15H31.5C32.25 15 33 14.25 33 13.5C33 12.75 32.25 12.0938 31.5 12.0938H16.5C15.6562 12.0938 15 12.8438 15 13.5C15 14.3438 15.6562 15 16.5 15Z"
        fill={FillColor}
      />
    </svg>
  );
}
export function ClosedEnvelopeIcon({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 0C15.3125 0 16 0.6875 16 1.5C16 2 15.75 2.4375 15.375 2.71875L8.59375 7.8125C8.21875 8.09375 7.75 8.09375 7.375 7.8125L0.59375 2.71875C0.21875 2.4375 0 2 0 1.5C0 0.6875 0.65625 0 1.5 0H14.5ZM6.78125 8.625C7.5 9.15625 8.46875 9.15625 9.1875 8.625L16 3.5V10C16 11.125 15.0938 12 14 12H2C0.875 12 0 11.125 0 10V3.5L6.78125 8.625Z"
        fill={FillColor}
      />
    </svg>
  );
}

export function CallCenterIcon({ FillColor }: IconProps): JSX.Element {
  return (
    <svg
      width="49"
      height="50"
      viewBox="0 0 49 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9062 23C18.9062 21.4062 17.5 20 15.9062 20H12.9062C9.53125 20 6.90625 22.7188 6.90625 26V32C6.90625 35.2812 9.625 38 12.9062 38H15.9062C17.5938 38 18.9062 36.6875 18.9062 35V23ZM15.9062 35H12.9062C11.2188 35 9.90625 33.6875 9.90625 32V26C9.90625 24.4062 11.2188 23 12.9062 23H15.9062V35ZM36.9062 38C40.1875 38 42.9062 35.2812 42.9062 32V26C42.9062 22.7188 40.1875 20.0938 36.9062 20.0938H34C32.3125 20.0938 31 21.5 31 23.0938V35.0938C31 36.7812 32.3125 38.0938 34 38.0938H36.9062V38ZM33.9062 23H36.8125C38.4062 23 39.8125 24.4062 39.8125 26V32C39.8125 33.6875 38.4062 35 36.8125 35H33.8125L33.9062 23ZM27.25 2.1875C12.8125 0.78125 1.375 12.3125 1 26.0938L0.90625 27.5C0.90625 28.3438 1.65625 29 2.5 29C3.25 29 3.90625 28.3438 4 27.5938V26C4.28125 14.0938 14.3125 3.96875 26.875 5.09375C37.8438 6.125 46 15.6875 46 26.6562V42.5C46 44.9375 43.9375 47 41.5 47H33.625C34.0938 45.9688 34.0938 44.75 33.4375 43.5312C32.5938 41.9375 30.8125 41 29.0312 41H23.5938C21.4375 41 19.4688 42.5 19 44.6562C18.5312 47.5625 20.6875 50 23.4062 50H41.3125C45.4375 50 48.8125 46.7188 48.8125 42.5938V26.6562C49 14.1875 39.625 3.3125 27.25 2.1875ZM29.4062 47H23.4062C22.5625 47 21.9062 46.3438 21.9062 45.5C21.9062 44.75 22.5625 44 23.4062 44H29.4062C30.1562 44 30.9062 44.75 30.9062 45.5C30.9062 46.3438 30.25 47 29.4062 47Z"
        fill={FillColor}
      />
    </svg>
  );
}

export function CirclesColumn({ width = 55, height = 354 }) {
  return (
    <svg
      width="55"
      height="354"
      viewBox="0 0 55 354"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="27.5"
        y="55.5"
        width="1"
        height="275"
        stroke="#DF0A0A"
        stroke-dasharray="2 2"
      />
      <circle cx="28" cy="287" r="11" fill="#DF0A0A" />
      <circle cx="28" cy="287" r="5" fill="white" />
      <circle cx="28" cy="343" r="11" fill="#DF0A0A" />
      <circle cx="28" cy="343" r="5" fill="white" />
      <circle cx="28" cy="231" r="11" fill="#DF0A0A" />
      <circle cx="28" cy="231" r="5" fill="white" />
      <circle cx="28" cy="174" r="11" fill="#DF0A0A" />
      <circle cx="28" cy="174" r="5" fill="white" />
      <circle cx="27.5" cy="27.5" r="27.5" fill="#DF0A0A" />
    </svg>
  );
}
