const data: ServiceProps[] = [
    {
        id: 1,
        title: "Marketing & Advertising:",
        text: "Customized campaigns to enhance brand visibility"
    },
    {
        id: 2,
        title: "Market Studies & Entry Strategies:",
        text: "Comprehensive analysis and tailored entry plans."
    },
    {
        id: 3,
        title: "Business Development:",
        text: "Strategic support to foster growth and expansion."
    },
    {
        id: 4,
        title: "Strategic Partnerships:",
        text: "Facilitation of collaborations to strengthen market presence."
    },
];

export default function Showroom() {
    return (
        <div className="w-full bg-dotted-gray flex justify-center items-center bg-cover bg-center py-10">
            <div className="w-10/12 flex flex-col justify-center items-center">
                <div className="w-full flex flex-col">
                    {/* Header Section */}
                    <div className="py-4 md:py-2">
                        <div className="flex flex-col gap-2">
                            <h1 className="capitalize font-redHat text-2xl md:text-5xl font-extrabold text-primaryOrange">
                                Showroom Services
                            </h1>
                            <p className="text-black font-dmSans text-xl font-medium py-2 md:py-10">
                                Our 1,200 square meter showroom is located on the 4th floor of the prestigious UDC
                                Tower in the Pearl, Doha. This space offers an open layout tailored to meet the
                                diverse needs of looking to establish a presence in Qatar. Our tenants benefit
                                from a range of services including:
                            </p>
                        </div>
                    </div>

                    {/* Services Section */}
                    <div className='grid grid-cols-1 md:grid-cols-2 md:grid-rows-2 gap-4 justify-center place-items-center'>
                        {data.map((item: ServiceProps) => (
                            <div
                                key={item.id}
                                className="bg-white w-10/12 border-l-[0.65rem] border-primaryOrange flex flex-col gap-4 justify-center items-start p-4"
                            >
                                <h2 className="text-primaryOrange font-montserrat text-xl md:text-2xl lg:text-3xl font-bold capitalize">
                                    {item.title}
                                </h2>
                                <p className="text-black font-montserrat text-md font-medium capitalize">
                                    {item.text}
                                </p>
                            </div>
                        ))}
                    </div>


                    {/* Footer Section */}
                    <div className="">
                        <p className="text-black font-dmSans text-xl font-medium py-10">
                            We aim to create a thriving hub that serves as a gateway for all industries,
                            from oil & gas to technology and design.
                        </p>
                    </div>
                </div>
            </div>
        </div>


    )
}
