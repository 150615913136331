import Finance from "../assets/images/our-team/finance-1.png";
import Trade from "../assets/images/our-team/trade-2.png";
import Industrial from "../assets/images/our-team/indust-3.png";
import Tech from "../assets/images/our-team/tech-4.png";
import Oil from "../assets/images/our-team/oil-5.png";
import Construction from "../assets/images/our-team/construction-6.png";
import Furniture from "../assets/images/our-team/furniture-7.png";
import Legal from "../assets/images/our-team/legal-8.png";
import Marketing from "../assets/images/our-team/marketing-9.png";
import Magnifier from "../assets/images/our-team/magnifier-10.png";

const TeamSpecsData = [
  {
    id: 1,
    category: "Financial and Managerial Experience",
    description:
      "Experts in financial planning, investment strategies, and business management.",
    image: Finance,
  },
  {
    id: 2,
    category: "Trade and Commerce",
    description:
      "Professionals adept at handling international trade, commodity transactions, and market analysis.",
    image: Trade,
  },
  {
    id: 3,
    category: "Industrial Engineering",
    description:
      "Skilled engineers with experience in optimizing industrial processes and systems.",
    image: Industrial,
  },
  {
    id: 4,
    category: "Technology and AI",
    description:
      "Innovators in technology and artificial intelligence, helping to navigate the complexities of modern tech markets.",
    image: Tech,
  },
  {
    id: 5,
    category: "Oil & Gas",
    description:
      "Strategic experts with deep knowledge of the oil and gas industry and its market dynamics.",
    image: Oil,
  },
  {
    id: 6,
    category: "Construction",
    description:
      "Consultants with a strong background in construction management and market development.",
    image: Construction,
  },
  {
    id: 7,
    category: "Furniture & Design",
    description:
      "Creative minds who understand the intricacies of the furniture and design markets.",
    image: Furniture,
  },
  {
    id: 8,
    category: "Legal and Compliance",
    description:
      "Legal experts ensuring adherence to market compliance and regulatory standards.",
    image: Legal,
  },
  {
    id: 9,
    category: "Marketing and Advertising",
    description:
      "Strategists dedicated to enhancing brand visibility and market presence.",
    image: Marketing,
  },
  {
    id: 10,
    category: "Market Research",
    description:
      "Analysts providing in-depth market studies and entry strategies.",
    image: Magnifier,
  },
];

export default TeamSpecsData;
