import { Link } from "react-router-dom";
import { RightArrow } from "../components/icons/Social-media";

const FeaturesCard = ({ image, mainTitle, subTitle, link }: any) => {
  return (
    <div>
      <div className="relative overflow-hidden rounded-3xl group">
        <div className="overflow-hidden rounded-3xl">
          <img
            src={image}
            alt="Tower"
            className="rounded-3xl w-full h-auto transition-transform duration-300 ease-in-out group-hover:scale-110"
          />
        </div>
        <div className="absolute inset-x-0 bottom-0 h-1/5 bg-gradient-to-t from-black via-[rgba(0,0,0,0.3)] to-transparent pointer-events-none rounded-b-3xl transition-all duration-250 ease-in-out group-hover:h-1/3 z-10" />
        <div className="absolute inset-x-0 bottom-0 p-5 z-20 flex flex-col justify-end h-full">
          <p className="text-2xl font-montserrat capitalize text-white font-bold transition-all duration-300 ease-in-out mb-2 line-clamp-2">
            {mainTitle}
          </p>
          <p className="text-sm text-white font-montserrat transition-all duration-300 ease-in-out opacity-0 max-h-0 overflow-hidden line-clamp-3 group-hover:opacity-100 group-hover:max-h-20">
            {subTitle}
          </p>
        </div>
      </div>
      <Link
        to={link}
        className="border flex flex-row gap-1 items-center justify-center bg-[#F64A00] w-fit px-8  text-white ml-12 -mt-1 text-nowrap py-5 rounded-b-2xl"
      >
        <p className="font-semibold font-montserrat">Read More</p>
        <RightArrow FillColor={"#FFFFFF"} />
      </Link>
    </div>
  );
};

export default FeaturesCard;
