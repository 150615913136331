"use client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../ui/form";
import { Input } from "./Input";
import { Textarea } from "./Textarea";

const formSchema = z.object({
  username: z.string().min(2).max(50),
  email: z.string().email(),
  subject: z.string().min(2).max(50),
  message: z.string().min(30).max(500),
});

export default function ContactForm() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const { username, email, subject, message } = values;

    const mailtoLink = `mailto:your-email@example.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(
      `Name: ${username}\nEmail: ${email}\n\nMessage:\n${message}`
    )}`;

    window.location.href = mailtoLink;
  }

  // function onSubmit(values: z.infer<typeof formSchema>) {
  //   // console.log(values);
  // }

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-10/12 flex flex-col gap-10 h-auto bg-white rounded-lg p-5 shadow-md">
        <div className="flex flex-col justify-end items-center gap-10">
          <div className="relative flex justify-center items-center">
            <div
              className="absolute top-0 font-redHat text-5xl text-center md:text-7xl lg:text-8xl font-extrabold text-white z-10 capitalize"
              style={{ WebkitTextStroke: "1px #F0F0F0" }}
            >
              contact us
            </div>
            <div className="z-20 md:mt-8">
              <h4 className="text-primary-red font-redHat font-semibold text-md text-center  pt-5 uppercase">
                get in touch
              </h4>
              <h3 className="text-black font-redHat font-bold text-xl md:text-5xl text-center pt-5 capitalize">
                need help? let's get in touch
              </h3>
            </div>
          </div>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col justify-center items-center space-y-8"
          >
            <div className="w-10/12 flex flex-col justify-center md:flex-row md:justify-between items-center gap-8">
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem className="w-full md:w-1/2">
                    <FormControl>
                      <Input
                        className="bg-inputFill font-dmSans border-none text-grayFill text-md"
                        placeholder="Your Name"
                        {...field}
                      />
                    </FormControl>
                    <div className="h-1">
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-full md:w-1/2">
                    <FormControl>
                      <Input
                        className="bg-inputFill font-dmSans border-none text-grayFill text-md"
                        placeholder="Email Address"
                        {...field}
                      />
                    </FormControl>
                    <div className="h-1">
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="subject"
              render={({ field }) => (
                <FormItem className="w-10/12">
                  <FormControl>
                    <Input
                      className="bg-inputFill font-dmSans border-none text-grayFill text-md"
                      placeholder="Type Your Subject"
                      {...field}
                    />
                  </FormControl>
                  <div className="h-1">
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem className="w-10/12">
                  <FormControl>
                    <Textarea
                      className="bg-inputFill font-dmSans border-none text-grayFill text-md resize-none"
                      placeholder="Type Your Message"
                      {...field}
                    />
                  </FormControl>
                  <div className="h-1">
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
            <Button
              className="font-dmSans"
              size={"lg"}
              variant={"default"}
              type="submit"
            >
              Send Message
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
