import React from 'react'
import { CirclesColumn } from './icons/Social-media';
import { pictures } from '../constants/pictures';

export default function OurCommitment() {
    return (
        <div className="w-full flex justify-center items-center relative overflow-hidden">
            <div className="w-11/12 lg:w-10/12 flex flex-col lg:flex-row items-stretch min-h-[40rem] relative bg-inputFill">
                {/* Left Section - Text */}
                <div className="flex flex-col gap-6 justify-center w-full lg:w-1/2 bg-inputFill px-6 lg:px-16 py-8 relative z-0">
                    <h1 className="text-2xl lg:text-4xl font-redHat font-bold text-primaryRoute mb-4 lg:mb-6">
                        Our Commitment
                    </h1>
                    <div className="flex flex-row gap-4 lg:flex-row lg:gap-6">
                        <div className="hidden md:flex flex-col justify-center lg:justify-end items-center">
                            <CirclesColumn />
                        </div>
                        <p className="font-dmSans text-grayFill text-left text-lg lg:text-base">
                            At Xinhua Age Trading Bridging China, Qatar, the GCC, and the Middle East was founded through the collaborative efforts of a prominent Qatari group and a diverse conglomerate of Sichuan-based companies. Our mission is to serve as a vital bridge, facilitating business and cultural exchange between Qatar and China. With our headquarters in Doha, we are strategically positioned to support companies in their pursuit of new market opportunities across the GCC (Gulf Cooperation Council).
                            <br />
                            <br />
                            Our mission goes beyond business transactions; we aim to build a robust bridge between China, Qatar, the GCC, and the broader Middle East. We are committed to breaking down the challenges that businesses often face in these regions. Join us on our mission to bridge continents, cultures, and markets. Together, we can break down barriers and create new opportunities for growth and success.
                        </p>
                    </div>
                </div>

                {/* Right Section - Image */}
                <div className="relative bg-inputFill w-full lg:w-[70%] hidden md:hidden lg:flex items-center justify-center lg:mt-0 lg:-ml-8 z-20">
                    <img
                        src={pictures.GroupHands}
                        alt="Commitment"
                        className="w-full h-full object-cover z-20"
                    />
                </div>
                <div className="lg:hidden relative bg-inputFill w-full h-[30rem] md:h-[50rem] z-20">
                    <img
                        src={pictures.MobileGroupHands}
                        alt="Commitment"
                        className="w-full h-full object-cover z-20"
                    />
                </div>
            </div>
        </div>


    )
}
