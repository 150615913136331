import Hero from "../components/Hero";
import Culture from "../components/Culture";
import WhyChooseUs from "../components/Why-choose-us";
import Footer from "../layouts/Footer";
import ValuesSection from "../components/Mission-vision-section";
import BusinessCards from "../components/Business-cards";
import OurFeatures from "../components/OurFeatures";
import WideWorkTogether from "../components/Wide-work-together";
import FullWidthBanner from "../components/Full-width-page-banner";
import AboutUsBannerImage from "../assets/images/main/about-us-banner.webp";
import AboutStats from "../components/About-stats";
import NarrowBannerSection from "../components/Narrow-banner-section";
import BridgingSection from "../components/Bridging-section";

export default function AboutUsPage() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 bg-inputFill">
      <FullWidthBanner
        image={AboutUsBannerImage}
        routing={"About Us"}
        LinkRoute={
          <>
            <p className="flex gap-2 font-dmSans font-normal text-md text-grayFill">
              Home
              <span className="text-primary-red">
                /
              </span>
              <span className="text-primaryRoute font-medium">
                About Us
              </span>
            </p>
          </>
        }
      />
      <AboutStats />
      <NarrowBannerSection />
      <div className="w-full md:w-10/12 lg:w-9/12 flex justify-center items-center py-8">
        <BridgingSection />
      </div>
    </div>
  );
}
