import React from "react";
import Description from "../assets/images/our-team/description.png";

export default function TeamDescription() {
  return (
    <div className="w-full flex flex-col gap-10 md:gap-20 justify-center items-center py-10 px-4">
      <div className="w-9/12 flex justify-center items-center">
        <p className="font-redHat text-lg md:text-3xl font-normal text-primaryRoute">
          At Xinhua Age Trading, our greatest asset is our team. Comprised of
          over 50 experienced professionals from both China and Qatar, as well
          as international experts, our diverse team brings a wealth of
          knowledge and expertise from a wide range of industries.
        </p>
      </div>
      <div className="w-10/12">
        <div className="w-full rounded-3xl  bg-team-our-desc flex justify-center items-center bg-no-repeat bg-cover bg-center min-h-80">
          <h2 className="font-redHat text-xl md:text-5xl text-white font-bold text-center">
            A Diverse and <br /> Experienced Team
          </h2>
        </div>
      </div>
    </div>
  );
}
