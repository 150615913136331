import FullWidthBanner from "../components/Full-width-page-banner";
import ServicesBannerImage from "../assets/images/main/our-services-banner.png";
import OtherServices from "../components/Other-services";

export default function OtherServicesPage() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 bg-inputFill">
      <FullWidthBanner
        image={ServicesBannerImage}
        routing={"Service Other"}
        LinkRoute={
          <>
            <p className="flex gap-2 font-dmSans font-normal text-md text-grayFill">
              Home
              <span className="text-primary-red">/</span>
              <span className="text-primaryRoute font-medium">
                Service Other{" "}
              </span>
            </p>
          </>
        }
      />
      <div className="py-4 md:py-10">
        <OtherServices />
      </div>
    </div>
  );
}
