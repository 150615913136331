import arrowRight from "../assets/icons/arrowRight.svg";
import facebook from "../assets/icons/facebook.svg";
import instagram from "../assets/icons/instagram.svg";
import linkedin from "../assets/icons/linkedin.svg";
import skype from "../assets/icons/skype.svg";
import x from "../assets/icons/x.svg";
import phone from "../assets/icons/phone.svg";
import mail from "../assets/icons/mail.svg";
import location from "../assets/icons/location.svg";

export const socialIcons = {
  facebook,
  instagram,
  linkedin,
  skype,
  x,
};

export const contactIcons = {
  phone,
  mail,
  location,
};

export const icons = {
  arrowRight,
};
