import React from "react";
import NewsletterForm from "./NewsletterForm";
import { pictures } from "../constants/pictures";

export default function Newsletter() {
  return (
    <div className="relative overflow-hidden h-60 md:h-40 w-full md:w-[90%] rounded-md md:rounded-full">
      <img
        src={pictures.NewsletterBackground}
        alt=""
        className="absolute w-full h-full z-10 object-cover"
      />
      <div className="absolute w-full h-full flex flex-col md:flex-row justify-around items-center z-20 md:px-8">
        <div className="flex flex-col text-white">
          <p className="uppercase font-medium font-redHat tracking-widest">latest business ideas</p>
          <p className="font-bold font-redHat capitalize text-3xl">sign up newsletter</p>
        </div>
        <NewsletterForm />
      </div>
    </div>
  );
}
