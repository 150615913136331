import { pictures } from "../constants/pictures";
import { Link } from "react-router-dom";
import InputwithButton from "../components/InputwithButton";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  InstagramIcon,
  SkypeIcon,
  PhoneCallIcon,
  MailIcon,
  LocationPinIcon,
} from "../components/icons/Social-media";
import { useAboutData, useSocialMediaData } from '../context/companyDataContext';
import XinhauLogo from "../assets/icons/xinhua-logo.png"

type ContactItemProps = {
  image: any;
  headText: string;
  text: string | string[];
  type: string;
};
type SocialLinkProps = {
  image: any;
  link: string;
};

export default function Footer() {
  const aboutData = useAboutData();
  const socialMediaData = useSocialMediaData();
  const phoneNumbers = aboutData?.phone_numbers;
  const socialLinks = socialMediaData;
  const baseURL = process.env.REACT_APP_BASE_URL;

  const socials = [
    {
      id: 1,
      name: "facebook",
      link: "https://www.facebook.com/",
      image: <FacebookIcon className="fill-current group-hover:fill-white" />,
    },
    {
      id: 2,
      name: "x",
      link: "https://x.com/",
      image: <TwitterIcon className="fill-current group-hover:fill-white" />,
    },
    {
      id: 3,
      name: "linkedin",
      link: "https://www.linkedin.com/",
      image: <LinkedinIcon className="fill-current group-hover:fill-white" />,
    },
    {
      id: 4,
      name: "instagram",
      link: "https://www.instagram.com/",
      image: <InstagramIcon className="fill-current group-hover:fill-white" />,
    },
    {
      id: 5,
      name: "skype",
      link: "https://www.skype.com/",
      image: <SkypeIcon className="fill-current group-hover:fill-white" />,
    },
  ];

  const contact = [
    {
      id: 1,
      type: "phone",
      image: <PhoneCallIcon FillColor="#f64a00" />,
      headText: "Call Us 24/7",
      text: ["+974 33605606 ", "+34 653875535 ", "+974 33996801"],
    },
    {
      id: 2,
      type: "mail",
      image: <MailIcon FillColor="#f64a00" />,
      headText: "Work with us",
      text: "Info@xinhuaage.com",
    },
    {
      id: 3,
      type: "location",
      image: <LocationPinIcon FillColor="#f64a00" />,
      headText: "Our Location",
      text: "UDC Tower, the pearl blvrd, 4th Floor, Doha, QA",
    },
  ];

  const ContactItem = ({ type, image, headText, text }: ContactItemProps) => {
    return (
      <div className="flex flex-row gap-4">
        {/* Icon Section */}
        <div className="flex items-center justify-center bg-white w-10 h-10 rounded-full">
          <div>{image}</div>
        </div>

        {/* Text Section */}
        <div className="flex flex-col gap-1">
          <p className="text-grayFill capitalize font-dmSans text-md font-normal">
            {headText}
          </p>
          {type === "phone" && Array.isArray(text) ? (
            phoneNumbers?.numbers?.map((phone: string, index: number) => (
              <Link
                key={index}
                className="font-bold font-dmSans text-primaryRoute max-w-56 text-sm hover:text-[#f64a00]"
                to={`tel:${phone}`}
              >
                {phone}
              </Link>
            ))
          ) : type === "mail" ? (
            <Link
              className="font-bold font-dmSans text-primaryRoute max-w-56 text-sm hover:text-[#f64a00]"
              to={`mailto:${text}`}
            >
              {aboutData?.emails?.primary}
            </Link>
          ) : (
            <p className="font-bold font-dmSans text-primaryRoute max-w-56 text-sm">{aboutData?.locations.primary}</p>
          )}
        </div>
      </div>
    );
  };

  const SocialLink = ({ image, link }: SocialLinkProps) => {
    return (
      <Link
        to={link}
        target="_blank"
        className="w-10 h-10 bg-[#e8e8e8] hover:bg-[#f64a00] rounded-md group flex items-center justify-center transition-colors duration-300"
      >
        <div className="group-hover:fill-white transition-colors duration-300">
          {image}
        </div>
      </Link>
    );
  };

  return (
    <footer className="flex flex-col lg:flex-row justify-around py-4 gap-10 items-center bg-footer-gray bg-no-repeat bg-cover bg-[#f6f6f6] lg:h-[50vh] pt-10">
      <div className="flex flex-col items-center justify-center text-center gap-10">
        {aboutData?.status ? (
          <img src={`${baseURL}/${aboutData?.website_logo}`} alt="Xinhau Logo" />
        ) : (
          <img src={XinhauLogo} alt="Xinhau Logo" />
        )}
        <p className="font-normal font-dmSans text-md text-grayFill">
          International Trade and Development
        </p>
        <div className="flex flex-row w-full justify-around gap-4">
          {socials.map((social) => {
            return (
              <div key={social.id}>
                <SocialLink image={social.image} link={social.link} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-4">
        <p className="text-2xl font-bold font-redHat">Office Information</p>
        <div className="flex flex-col gap-4">
          {contact.map((contact, index) => {
            return (
              <div key={index}>
                <ContactItem
                  image={contact.image}
                  headText={contact.headText}
                  text={contact.text}
                  type={contact.type}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col justify-center items-left gap-4">
        <p className="text-2xl font-bold font-redHat text-primaryRoute">
          Get Updates
        </p>
        <p className="text-left text-grayFill font-dmSans font-normal text-md">
          Sign up for our latest news & articles.
          <br />
          We won’t give you spam mails.
        </p>
        <div className="w-full flex justify-start items-start">
          <InputwithButton />
        </div>
        <div className="flex flex-row gap-1">
          <p className="text-primaryOrange font-dmSans text-md">
            Note:
            <span className="text-grayFill ml-1">
              We do not publish your email
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
}
